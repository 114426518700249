<template>
    <div>
        <section class="header-bg" style="">
        </section>

        <div class="page_ai_container">
            <div class="ai_container">
                <div class="ai_headline">
                    <headlineVue></headlineVue>
                </div>

                <div class="ai_character">
                    <characterVue></characterVue>
                </div>

                <div class="ai_around_list">
                    <aroundListVue></aroundListVue>
                </div>

                <div class="ai_percent">
                    <percentVue></percentVue>
                </div>

                <div class="ai_case">
                    <caseVue></caseVue>
                </div>
            </div>
        </div>

        <div class="ai_feed ss">
            <feedVue></feedVue>

            <img src="../../assets/images/ai/icon_d.png" alt="" class="feed_icon_d">
        </div>
    </div>
</template>

<script>
import { pageContentList } from "./data";
import headlineVue from "./headline.vue";
import characterVue from "./character.vue";
import aroundListVue from "./aroundList.vue";
import percentVue from "./percent.vue";
import caseVue from "./case.vue";
import feedVue from "./feed.vue";

export default {
    components: {
        headlineVue,
        characterVue,
        aroundListVue,
        percentVue,
        caseVue,
        feedVue
    },
    data() {
        return {
            pageContentList
        }
    }
}
</script>

<style lang='scss'>
@import "../../assets/css/minix.scss";

.page_ai_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(rgb(237 237 237 / 40%), rgb(255 255 255 / 50%)),
        linear-gradient(hsl(0deg 0% 100% / 50%), hsl(0deg 0% 100% / 50%)),
        url(/img/ai_conbj.f4e7f516.png) no-repeat;
    background-size: contain;
    background-repeat: no-repeat;

    .ai_container {
        @include responsive-width(1200px);

        .ai_headline,
        .ai_character,
        .ai_around_list,
        .ai_percent,
        .ai_case {
            display: flex;
            justify-content: center;
            margin: 100px 0;
        }
    }
}

.ai_feed {
    position: relative;

    .feed_icon_d {
        position: absolute;
        top: -44px;
        right: 20%;
    }
}

@media screen and (max-width: 768px) {
    .ai_container {

        .ai_headline,
        .ai_character,
        .ai_around_list,
        .ai_percent,
        .ai_case {
            margin: 50px 0 !important;
        }
    }

    .ai_feed {
        position: relative;

        .feed_icon_d {
            width: 50px;
            top: -5%;
            right: 7%;
        }
    }
}
</style>