<template>
    <div class="ai_percent_box">
        <div class="ai_percent_list" v-for="(item, index) in percentDesc" :key="index">
            <div class="ai_percent_list_icon">
                <img src="../../assets/images/ai/icon_t.png" alt="">
            </div>
            <div class="ai_percent_list_num">
                {{ item.num }}
            </div>
            <div class="ai_percent_list_desc">
                {{ item.desc }}
            </div>
        </div>
    </div>
</template>

<script>
import { percentDesc } from './data';

export default {
    data() {
        return {
            percentDesc
        }
    }
}
</script>

<style lang='scss'>
.ai_percent_box {
    display: flex;
    gap: 40px;

    .ai_percent_list {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        flex: 0 0 33%;
        text-align: center;

        .ai_percent_list_icon {
            img {
                height: 4px;
                width: 50px;
            }
        }

        .ai_percent_list_num {
            font-size: 50px;
            color: #1da6b8;
            font-weight: bold;
        }

        .ai_percent_list_desc {
            font-family: none;
            color: black;
            line-height: 1.5;
        }
    }
}

@media screen and (max-width: 576px) {
    .ai_percent_box {
        flex-direction: column;
        gap: 20px;

        .ai_percent_list {
            flex: initial;

            .ai_percent_list_desc {
                max-width: 300px;
            }
        }
    }
}
</style>