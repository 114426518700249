<template>
    <div class="feed_box" id="feed_box">
        <el-carousel height="400px" :autoplay="true">
            <el-carousel-item v-for="(item, index) in featureList" :key="index">
                <div class="feed_item">
                    <div class="feed_img">
                        <img :src="item.image" alt="">
                    </div>
                    <div class="feed_content">
                        <div class="feed_name">{{ item.name }}</div>
                        <div class="feed_desc">{{ item.content }}</div>
                        <div class="feed_pub">{{ item.pub }}</div>
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
import { featureList } from './data';

export default {
    data() {
        return {
            featureList
        }
    }
}
</script>

<style lang='scss'>
.feed_box {
    background: linear-gradient(to right, #d5f7fb, rgba(255, 255, 255, 0.5));

    .el-carousel__item {
        display: flex;
        justify-content: center;
    }

    .el-carousel__indicator.is-active button {
        opacity: 1;
        background: #1da6b8;
    }

    .el-carousel__button {
        padding: 2px;
        border-radius: 12px;
        background: #bfeff5;
    }

    .el-carousel__indicator--horizontal {
        margin: 0 3px;
    }

    .feed_item {
        width: 1200px;
        display: flex;
        align-items: center;

        .feed_img {}

        .feed_content {
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-left: 50px;

            .feed_name {}

            .feed_desc {
                font-size: 18px;
                color: #35393d;
            }

            .feed_pub {
                font-size: 20px;
                color: black;
            }
        }
    }
}

@media (max-width: 768px) {
    #feed_box {
        .feed_item {
            padding: 0 20px;

            .feed_img {
                img {
                    width: 132px;
                }
            }

            .feed_content {
                margin-left: 20px;
            }

            .feed_desc {
                font-size: 14px;
            }

            .feed_pub {
                font-size: 16px;
            }
        }
    }
}
</style>