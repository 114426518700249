<template>
    <div class="ai_character_box">
        <div v-for="(item, index) in bannerData" :key="index" class="ai_character_item">
            <div class="ai_character_img">
                <img :src="item.image" alt="">
            </div>
            <div class="ai_character_title">
                {{ item.title }}
            </div>
            <div class="ai_character_desc">
                {{ item.desc }}
            </div>
        </div>
    </div>
</template>

<script>
import { bannerData } from "./data";

export default {
    data() {
        return {
            bannerData
        }
    }
}
</script>

<style lang='scss'>
.ai_character_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: white;
    border-radius: 6px;
    padding: 30px 60px;
    box-shadow: 0 0 20px 2px #f3f3f3;

    .ai_character_item {
        flex: 0 0 33%;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 10px;
        text-align: center;

        .ai_character_img {
            img {
                width: 50px;
                height: 50px;
            }
        }

        .ai_character_title {
            font-size: 20px;
            font-weight: bold;
        }

        .ai_character_desc {
            font-size: 14px;
            color: #657186;
            line-height: 1.5;
        }
    }
}

@media (max-width: 576px) {
    .ai_character_box {
        gap: 20px;
        padding: 30px;

        .ai_character_item {
            flex: initial;
            width: 100%;
        }
    }
}
</style>