export const bannerData = [
    {
        image: require('@/assets/images/ai/banner1.svg'),
        title: "24/7 Access to Help",
        desc: "Providing help on-demand to your customers, where and when they need it."
    },
    {
        image: require('@/assets/images/ai/banner2.svg'),
        title: "No Code Needed",
        desc: "Integrates into any website without any coding."
    },
    {
        image: require('@/assets/images/ai/banner3.svg'),
        title: "Links with all Publishers",
        desc: "All published content flow to all over the world."
    }
]

export const pageContentList = [
    {
        title: "Rapid Information Retrieval",
        image: require('@/assets/images/ai/part1.png'),
        descList: [
            "Offer quick keyword search for easy data retrieval",
            "Activated AI search delivers tailored AI responses"
        ]
    },
    {
        title: "Multi-device Support",
        image: require('@/assets/images/ai/part2.png'),
        descList: [
            "Fully responsive and mobile/PC/laptop/tablet friendly layout"
        ]
    },
    {
        title: "Data Analysis",
        image: require('@/assets/images/ai/part3.png'),
        descList: [
            "Track widget usage, identify popular documents and search topics",
            "Extract insights from customer interactions and generate data reports"
        ]
    }
]

export const percentDesc = [
    {
        num: '50%',
        desc: "reduction in onboarding time"
    },
    {
        num: '64%',
        desc: "reported improvement in data-driven decision making"
    },
    {
        num: '91%',
        desc: "of questions resolved without escalation"
    }
]

export const featureList = [
    {
        image: require('@/assets/images/ai/test.png'),
        name: "Customer Reviews",
        content: "This widget integrates seamlessly into our website without writing a single line of code, significantly cutting down our development time and expenses. Furthermore, it offers on-demand assistance to our customers across various devices, ensuring that no matter what device they're using, help is always just a moment away.",
        pub: "Kneopen Publishing Editor丨Emily"
    },
    {
        image: require('@/assets/images/ai/test.png'),
        name: "Customer Reviews",
        content: "This widget integrates seamlessly into our website without writing a single line of code, significantly cutting down our development time and expenses. Furthermore, it offers on-demand assistance to our customers across various devices, ensuring that no matter what device they're using, help is always just a moment away.",
        pub: "Kneopen Publishing Editor丨Emily"
    },
    {
        image: require('@/assets/images/ai/test.png'),
        name: "Customer Reviews",
        content: "This widget integrates seamlessly into our website without writing a single line of code, significantly cutting down our development time and expenses. Furthermore, it offers on-demand assistance to our customers across various devices, ensuring that no matter what device they're using, help is always just a moment away.",
        pub: "Kneopen Publishing Editor丨Emily"
    }
]