<template>
    <div class="ai_case">
        <div class="title_text">
            Trusted by <span class="storng_text">100+</span> Journals in Various Industries
        </div>
        <div class="evaluate_issue">
            <CustomerList :isShowTitle="false" :isShowStatistics="false"></CustomerList>
        </div>
    </div>
</template>

<script>
import CustomerList from "@/components/customerList/index.vue"

export default {
    components: {
        CustomerList
    },
    data() {
        return {

        }
    }
}
</script>

<style lang='scss'>
.ai_case {
    flex-direction: column;

    .title_text {
        font-size: 24px;
        text-align: center;
        margin-bottom: 20px;

        .storng_text {
            font-size: 40px;
            font-weight: bold;
            margin: 0 4px;
        }
    }
}
</style>