<template>
    <div class="around_list_box">
        <AroundItem v-for="(item, index) in pageContentList" :key="index" :reverse="index % 2 == 0">
            <template #image>
                <img :src="item.image" alt="">
            </template>
            <template #content>
                <div class="content">
                    <div class="title page_title_">{{ item.title }}</div>
                    <div v-for="(it, i) in item.descList" :key="i" class="desc_item">
                        <i class="el-icon-check"></i>
                        <span class="desc">{{ it }}</span>
                    </div>
                </div>
            </template>
        </AroundItem>
    </div>
</template>

<script>
import { pageContentList } from "./data";
import AroundItem from "@/components/aroundItem/index.vue"

export default {
    components: {
        AroundItem
    },
    data() {
        return {
            pageContentList
        }
    }
}
</script>

<style lang='scss'>
.around_list_box {
    width: 100%;

    .content {
        display: flex;
        flex-direction: column;

        .title {
            margin-bottom: 20px;
        }

        .desc_item {
            margin-bottom: 10px;
            display: flex;
            align-items: center;

            i {
                background-color: #1da6b8;
                padding: 2px;
                border-radius: 50%;
                color: white;
                margin-right: 10px;
            }
        }
    }
}
</style>