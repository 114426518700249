<template>
    <div :class="reverse ? 'around_item around_reverse' : 'around_item'">
        <div class="around_iamge">
            <slot name="image"></slot>
        </div>
        <div class="item_text_box">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        reverse: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">
.around_item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
        margin-bottom: 40px;
    }
}

.around_reverse {
    flex-direction: row-reverse;
}

.item_text_box {
    flex: 1;
    padding: 20px;
}

.around_iamge img {
    width: 100%;
    height: auto;
}

@media screen and (max-width: 768px) {

    .around_reverse,
    .around_item {
        flex-direction: column-reverse;
    }
}
</style>