<template>
    <div class="why_list">
        <div>✓ No setup fee</div>
        <div>✓ Apply plugin code free of charge</div>
        <div>✓ Customer service 24/7</div>
        <div>✓ Cancel any time</div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    }
}
</script>

<style lang='scss'>
.why_list {
    text-align: center;
    margin-top: 40px;
    display: flex;
    font-size: 14px;
    color: #65676b;

    div {
        margin: 0 10px;
    }
}

@media (max-width: 768px) {
    .why_list {
        flex-wrap: wrap;

        div {
            flex: 0 0 43%;
            margin-bottom: 10px;
        }
    }
}
</style>